import React, {useState, useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from "apexcharts";

type SaleData = {
    year: number;
    revenue: number;
    totalOrders: number;
};

type ChartSeries = {
    name: string;
    type: 'column' | 'line';
    data: number[];
    yaxis?: string;
};

const options: ApexOptions = {
    chart: {
        type: 'line',
        height: 450,
        stacked: false,
    },
    xaxis: {
        title: {
            text: 'Year',
        },
    },
    yaxis: [
        {
            title: {
                text: 'Revenue',
            },
            seriesName: 'Revenue',
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#000',
            },
        },
        {
            opposite: true,
            title: {
                text: 'Total Orders',
            },
            seriesName: 'Total Orders',
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#008FFB',
            },
        },
    ],
    tooltip: {
        shared: false,
    },
};

const salesData: SaleData[] =
    [{year: 2024, revenue: 10000, totalOrders: 500},
        {year: 2023, revenue: 8000, totalOrders: 400},
    ]


const AccumulatedSaleChart = () => {
    const [series, setSeries] = useState<any[]>([]);

    useEffect(() => {
        if (salesData) {
            const formattedSeries = [
                {
                    name: 'Revenue',
                    type: 'column',
                    data: salesData.map((sale) => sale.revenue),
                },
                {
                    name: 'Total Orders',
                    type: 'line',
                    data: salesData.map((sale) => sale.totalOrders),
                    yaxis: 'y2', // Specify secondary y-axis for total orders
                },
            ];
            setSeries(formattedSeries);
        }
    }, [salesData]);

    return <ReactApexChart type="line" options={options} series={series} height={450}/>;

};

export default AccumulatedSaleChart;
