import {useEffect, useState} from "react";
import {Grid, CardContent, Typography} from "@mui/material";
import AnalyticCard from "./AnalyticCard";
import RevenueChart from "./RevenueChart";
import TopSellingTable from "./TopSellingTable";
import SaleByStoreChart from "./SaleByStoreChart";
import ContributeByStoreChart from "./ContributeByStoreChart";
import CustomerOverviewChart from "./CustomerOverviewChart";
import AccumulatedSaleChart from "./AccumulatedSaleChart";
import DailySaleTable from "./DailySaleTable";


const DashBoardPage = () => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    })
    return (
        // Time + datepicker
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12}>
                <Typography variant="h5">Dashboard</Typography>
            </Grid>

            {/*List revenue*/}

            {/*<Grid container rowSpacing={4.5} columnSpacing={2.75} justifyContent="space-between">*/}
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticCard title="Revenue" count="4,42,236" percentage={59.3} extra="35,000"/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticCard title="Total cups" count="78,250" percentage={70.5} extra="8,900"/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticCard title="Total Orders" count="18,800" percentage={27.4} isLoss color="warning"
                              extra="1,943"/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticCard title="Total Sales" count="$35,078" percentage={27.4} isLoss color="warning"
                              extra="$20,395"/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticCard title="Customers" count="35,078" percentage={27.4} isLoss color="warning"
                              extra="$20,395"/>
            </Grid>
            {/*</Grid>*/}

            {/*Revenue + order*/}
            <Grid item xs={8}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5">Revenue Order</Typography>
                        <RevenueChart/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Top selling</Typography>
                        <TopSellingTable/>
                    </Grid>
                </Grid>
            </Grid>

            {/*Store*/}
            <Grid item xs={4}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5">Sale scope</Typography>
                        <SaleByStoreChart/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Contribute store</Typography>
                        <ContributeByStoreChart/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Customer Overview</Typography>
                        <CustomerOverviewChart/>
                    </Grid>
                </Grid>
            </Grid>

            {/*Sale year*/}

            <Grid item xs={6}>
                <Typography variant="h5">Sale year</Typography>
                <AccumulatedSaleChart/>
            </Grid>

            {/*Sale month*/}

            <Grid item xs={6}>
                <Typography variant="h5">Sale month</Typography>
                <AccumulatedSaleChart/>
            </Grid>

            {/*daily sale last 30 day*/}
            <Grid item xs={12}>
                <Typography variant="h5">Daily sale</Typography>
                <DailySaleTable/>
            </Grid>
        </Grid>
    )
}

export default DashBoardPage;
