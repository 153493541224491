import {useState, useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from "apexcharts";

type Customer = {
    storeId: string;
    newCustomers: number;
    oldCustomers: number;
    naCustomers: number;
};

const customers: Customer[] = [
    {storeId: 'Shop A', newCustomers: 1000, oldCustomers: 300, naCustomers: 3000},
    {storeId: 'Shop B', newCustomers: 200, oldCustomers: 400, naCustomers: 1000},
    {storeId: 'Shop C', newCustomers: 300, oldCustomers: 600, naCustomers: 2000},
    {storeId: 'Shop D', newCustomers: 1500, oldCustomers: 700, naCustomers: 5000},

];


const areaChartOptions: ApexOptions = {
    chart: {
        type: 'polarArea',
        height: 350,
    },
    legend: {
        show: true,
        position: 'bottom',
        labels: {
            useSeriesColors: true,
        },
    },

    labels: ['New', 'Old', 'N/A'],
};


const CustomerOverviewChart = () => {
    const [series, setSeries] = useState<number[]>([]);
    const [labels, setLabels] = useState<any>([]);
    const [options, setOptions] = useState(areaChartOptions);
    useEffect(() => {
        if (customers) {
            const totalCustomersPerStore = customers.map(
                (store) => store.newCustomers + store.oldCustomers + store.naCustomers
            );
            console.log('total', totalCustomersPerStore)
            const formattedSeries = customers.map((store, index) => {
                const newCustomerPercentage = (store.newCustomers / totalCustomersPerStore[index]) * 100;
                const oldCustomerPercentage = (store.oldCustomers / totalCustomersPerStore[index]) * 100;
                const naCustomerPercentage = (store.naCustomers / totalCustomersPerStore[index]) * 100;
                return [newCustomerPercentage, oldCustomerPercentage, naCustomerPercentage];
            });
            setSeries(formattedSeries.map((percentages) => percentages.reduce((sum, value) => sum + value, 0)).filter((value) => value !== 0));
        }
    }, [customers]);


    return <ReactApexChart type="polarArea" options={options} series={series} height={450}/>;
};


export default CustomerOverviewChart;
