import React from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import PrivateRoutes from "./routes/PrivateRoutes";
import PrivateLayout from "./layout/PrivateLayout";
import DashBoardPage from "./pages/dashboard/DashBoardPage";
import PublicLayout from "./layout/PublicLayout";
import LoginPage from "./pages/authentication/LoginPage";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<PrivateLayout/>}>
                    <Route path="/dashboard" element={<DashBoardPage/>}/>
                </Route>

                <Route path="/" element={<PublicLayout/>}>
                    <Route path="/login" element={<LoginPage/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
