import {useState, useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from "apexcharts";

type Shop = {
    name: string;
    revenue: number;
};

const shopData: Shop[] = [
    {name: 'Shop A', revenue: 1000},
    {name: 'Shop B', revenue: 1500},
    {name: 'Shop C', revenue: 1500},
    {name: 'Shop D', revenue: 2500},
    {name: 'Shop E', revenue: 3500},
    {name: 'Shop F', revenue: 6500},
    {name: 'Shop G', revenue: 11500},
    {name: 'Shop H', revenue: 15000},

];


const areaChartOptions: ApexOptions = {
    chart: {
        type: 'pie',
        height: 350,
    },
    dataLabels: {
        enabled: true,
        formatter: (val: number) => `${val.toFixed(2)}%`, // Format data labels to show percentage
    },
    legend: {
        show: true,
        position: 'bottom',
        labels: {
            useSeriesColors: true,
        },
    },

    labels: ['Shop A', 'Shop B', 'Shop C', 'Shop D', 'Shop E', 'Shop F', 'Shop G', 'Shop H']
};


const ContributeByStoreChart = () => {
    const [series, setSeries] = useState<number[]>([]); // Use number[] for revenue percentages
    const [labels, setLabels] = useState<any>([]);
    const [options, setOptions] = useState(areaChartOptions);
    useEffect(() => {
        if (shopData) {
            const totalRevenue = shopData.reduce((sum, shop) => sum + shop.revenue, 0);
            const formattedData = shopData.map((shop) => {
                const percentage = (shop.revenue / totalRevenue) * 100;
                return parseFloat(percentage.toFixed(2)); // Round to two decimal places
            });
            setLabels(shopData?.map((shop) => shop?.name));
            setSeries(formattedData);
        }
    }, [shopData]);


    return <ReactApexChart type="pie" options={options} series={series} height={450}/>;
};


export default ContributeByStoreChart;
