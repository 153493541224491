import React from 'react';
import {Link} from 'react-router-dom';
import {Grid} from '@mui/material';

const LoginPage = () => {
    return (
        <>
            <Grid>
                Login
            </Grid>
        </>
    )

}

export default LoginPage;
