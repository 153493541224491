import {useState, useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from "apexcharts";

type ShopData = {
    name: string;
    customerCount: number;
    sales: number;
};

const areaChartOptions: ApexOptions = {
    chart: {
        height: 450,
        type: 'bubble',
        toolbar: {
            show: false
        }
    },
    fill: {
        opacity: 0.8
    },
    stroke: {
        curve: 'smooth',
        width: 2
    },
    grid: {
        strokeDashArray: 0
    },
    xaxis: {
        title: {
            text: 'Sales',
        },
    },
    yaxis: {
        title: {
            text: 'Customers',
        },
    }
};

const shopData = [
    {name: 'Shop A', customerCount: 100, sales: 800},
    {name: 'Shop B', customerCount: 150, sales: 1200},
    {name: 'Shop C', customerCount: 150, sales: 1200},
    {name: 'Shop D', customerCount: 150, sales: 1200},
    {name: 'Shop E', customerCount: 150, sales: 1200},
    {name: 'Shop F', customerCount: 150, sales: 1200},
    {name: 'Shop G', customerCount: 300, sales: 3200},
    {name: 'Shop H', customerCount: 500, sales: 12000},
];

const SaleByStoreChart = () => {
    const [series, setSeries] = useState<any[]>(shopData);
    const [options, setOptions] = useState(areaChartOptions);

    useEffect(() => {
        if (shopData) {
            const formattedSeries = shopData?.map((shop) => ({
                name: shop.name,
                data: [
                    {
                        x: shop.customerCount,
                        y: shop.sales,
                    },
                ],
            }));
            setSeries(formattedSeries);
        }
    }, [shopData]);

    return <ReactApexChart type="bubble" options={options} series={series} height={450}/>;
};


export default SaleByStoreChart;
