import {forwardRef} from "react";
import {Card, CardContent, CardHeader, Divider, Typography} from "@mui/material";


interface CardComponentProps {
    children: React.ReactNode;
    content?: boolean;
    contentClass?: string;
    darkTitle?: boolean;
    secondary?: React.ReactNode | string | object;
    sx?: React.CSSProperties; // Optional styles for card
    contentSX?: React.CSSProperties; // Optional styles for CardContent
    title?: React.ReactNode | string;
}

const CardComponent = forwardRef<HTMLDivElement, CardComponentProps>(
    ({
         children,
         content = true,
         contentClass,
         darkTitle,
         secondary,
         sx = {},
         contentSX = {},
         title,
         ...others
     }, ref) => {

        return (
            <Card
                ref={ref}
                sx={{
                    border: '1px solid',
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    },
                    ...sx
                }}
                {...others}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader sx={{p: 2.5}} title={<Typography variant="h5">{title}</Typography>}/>
                )}
                {darkTitle && title && (
                    <CardHeader sx={{p: 2.5}} title={<Typography variant="h4">{title}</Typography>}/>
                )}

                {/* content & header divider */}
                {title && (
                    <Divider
                        sx={{
                            opacity: 1,
                        }}
                    />
                )}

                {/* card content */}
                {content && (
                    <CardContent sx={{p: 2.5, ...contentSX}} className={contentClass || ''}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default CardComponent;
