import {Box, Chip, Grid, Stack, Typography} from '@mui/material';

import CardComponent from "../../components/CardComponent";
import {TrendingDownOutlined, TrendingUpOutlined} from "@mui/icons-material";

interface AnalyticCardProps {
    color?: string;
    title: string;
    count: string;
    percentage?: number;
    isLoss?: boolean;
    extra: string | React.ReactNode;
}

const AnalyticCard: React.FC<AnalyticCardProps> = ({color, title, count, percentage, isLoss, extra}) => (
    <CardComponent contentSX={{padding: 2.25}}>
        <Stack spacing={0.5}>
            <Typography variant="h6" color="textSecondary">
                {title}
            </Typography>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="inherit">
                        {count}
                    </Typography>
                </Grid>
                {percentage && (
                    <Grid item>
                        <Chip
                            icon={
                                <>
                                    {!isLoss && <TrendingUpOutlined style={{fontSize: '0.75rem', color: 'inherit'}}/>}
                                    {isLoss && <TrendingDownOutlined style={{fontSize: '0.75rem', color: 'inherit'}}/>}
                                </>
                            }
                            label={`${percentage}%`}
                            sx={{ml: 1.25, pl: 1}}
                            size="small"
                        />
                    </Grid>
                )}
            </Grid>
        </Stack>
    </CardComponent>
);


export default AnalyticCard;
