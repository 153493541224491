import {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";

import {useTheme} from "@mui/material";
import {Box, Toolbar, useMediaQuery} from "@mui/material";


const PrivateLayout = () => {
    // for responsive purpose
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Box component="main"  sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Outlet/>
            </Box>
        </Box>

    )
}

export default PrivateLayout;
