import {useState, useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from "apexcharts";

const areaChartOptions: ApexOptions = {
    chart: {
        height: 450,
        type: 'line',
        toolbar: {
            show: false
        }
    },
    stroke: {
        curve: 'smooth',
        width: 2
    },
    grid: {
        strokeDashArray: 0
    }
};

const RevenueChart = () => {
    const [series, setSeries] = useState([
        {
            name: 'Posthallen',
            data: [0, 86, 28, 115, 48, 210, 136]
        },
        {
            name: 'Sandivika',
            data: [0, 43, 14, 56, 24, 105, 68]
        }
    ]);
    const [options, setOptions] = useState(areaChartOptions);

    return <ReactApexChart type="line" options={options} series={series} height={450}/>;
};


export default RevenueChart;
