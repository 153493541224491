import {useState, useEffect} from 'react';
import {
    Box,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';


const TopSellingTable = () => {
    function createData(
        rank: number,
        name: string,
        items: number,
        growthRate: number,
        totalSales: number,
        percentageSales: number,
        status: string,
    ) {
        return {rank, name, items, growthRate, totalSales, percentageSales, status};
    }

    const rows = [
        createData(1, 'test1', 159, 6.0, 24, 4.0, 'UP'),
        createData(2, 'test1', 237, 9.0, 37, 4.3, 'UP'),
        createData(3, 'test1', 262, 16.0, 24, 6.0, 'UP'),
        createData(4, 'test1', 305, 3.7, 67, 4.3, 'DOWN'),
        createData(1, 'test1', 159, 6.0, 24, 4.0, 'UP'),
        createData(2, 'test1', 237, 9.0, 37, 4.3, 'UP'),
        createData(3, 'test1', 262, 16.0, 24, 6.0, 'UP'),
        createData(4, 'test1', 305, 3.7, 67, 4.3, 'DOWN')

    ];

    return (
        <Box>
            <TableContainer>
                <Table sx={{minWidth: 650}} aria-label="simple table">

                    {/*Head*/}
                    <TableHead>
                        <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Items</TableCell>
                            <TableCell align="right">Growth Rate</TableCell>
                            <TableCell align="right">Total Sales</TableCell>
                            <TableCell align="right">Total Sales</TableCell>
                            <TableCell align="right">% Sale</TableCell>
                            <TableCell align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>

                    {/*Body*/}
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.rank}</TableCell>
                                <TableCell align="right">{row.name}</TableCell>
                                <TableCell align="right">{row.items}</TableCell>
                                <TableCell align="right">{row.growthRate}</TableCell>
                                <TableCell align="right">{row.totalSales}</TableCell>
                                <TableCell align="right">{row.percentageSales}</TableCell>
                                <TableCell align="right">{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
};


export default TopSellingTable;
